<template>
  <div class="app-container">
    <button class="sidebar-toggle" @click="toggleSidebar">☰</button>
    <aside :class="['sidebar', { 'sidebar-closed': isSidebarClosed }]">
      <div class="sidebar-content">
        <!-- First Section -->
        <div class="sidebar-section">
          <div class="sidebar-title">Toon gebruikte documenten</div>
          <div class="sidebar-option">
            <input type="radio" id="option1-1" name="section1" value="true" v-model="section1Selected">
            <label for="option1-1">Ja</label>
          </div>
          <div class="sidebar-option">
            <input type="radio" id="option1-2" name="section1" value="false" v-model="section1Selected">
            <label for="option1-2">Nee</label>
          </div>
        </div>

        <!-- Second Section -->
        <div class="sidebar-section">
          <div class="sidebar-title">Welk type data moet SquadraChat gebruiken?</div>
          <div class="sidebar-option">
            <input type="radio" id="option2-1" name="section2" value="false" v-model="section2Selected">
            <label for="option2-1">Squadra data</label>
          </div>
          <div class="sidebar-option">
            <input type="radio" id="option2-2" name="section2" value="true" v-model="section2Selected">
            <label for="option2-2">Squadra data + ChatGPT</label>
          </div>
        </div>

        <!-- Third Section -->
        <!-- <div class="sidebar-section">
          <div class="sidebar-title">Laat confidence score zien</div>
          <div class="sidebar-option">
            <input type="radio" id="option3-1" name="section3" value="true" v-model="section3Selected">
            <label for="option3-1">Ja</label>
          </div>
          <div class="sidebar-option">
            <input type="radio" id="option3-2" name="section3" value="false" v-model="section3Selected">
            <label for="option3-2">Nee</label>
          </div>
        </div> -->

        <div class="sidebar-section">
          <button @click="removeHistory" class="remove-button">Verwijder chat geschiedenis</button>
        </div>
        <div class="sidebar-section">
          <button @click="logout" class="logout-button">Logout</button>
        </div>
      </div>
    </aside>
    <main class="main-content">
      <div class="header">
        <h1 class="page-title">SquadraChat</h1>
        <img :src="chatbotLogo" class="message-logo" />
      </div>
      <div class="chat-container">
        <div class="messages">
        <message-component
          v-for="message in messages"
          :key="message.id"
          :message="message"
          :isUser="message.role === 'user'"
          class="message-content"
        ></message-component>
        </div>
        <div class="input-area">
          <textarea v-model="userInput" @keyup.enter="sendMessage" placeholder="Vraag SquadraChat..."></textarea>
          <button @click="sendMessage" class="send-button">></button>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import MessageComponent from './MessageComponent.vue'

export default {
  updated() {
    this.scrollToBottom();
  },
  data() {
    return {
      messages: [],
      history: [],
      userInput: '',
      isSidebarClosed: false,
      userLogo: require('@/assets/user.png'),
      chatbotLogo: require('@/assets/chatbot.png'),
      section1Selected: 'true', // Default selection for the first section
      section2Selected: 'false', // Default selection for the second section
      section3Selected: 'false', // Default selection for the third section
    };
  },
  components: { MessageComponent },
  methods: {
    toggleSidebar() {
      this.isSidebarClosed = !this.isSidebarClosed;
    },
    sendMessage() {
      if (this.userInput.trim()) {
        const newMessage = {
          content: this.userInput,
          role: 'user'
        };
        this.messages.push(newMessage);
        this.userInput = '';
        this.getChatbotResponse();
      }
    },
    async getChatbotResponse() {
      const url = `/api/ask`;
      const headers = {
        "Content-Type": "application/json",
        "accept": "application/json",
        "Authorization": `Bearer ${process.env.VUE_APP_BEARER_TOKEN}` // Use BEARER_TOKEN from .env
      };
      const data = {
        "user_question": this.messages[this.messages.length - 1].content,
        "history": this.history,
        "show_documents": this.section1Selected === "true",
        "use_chatgpt": this.section2Selected === "false",
        "add_confidence_score": this.section3Selected === "true"
      }
      try {
        const response = await this.$axios.post(url, data, { headers });
        const newMessage = {
          content: response.data.result,
          role: 'chatbot'
        }
        this.messages.push(newMessage);
        this.history.push(newMessage);
      }
      catch (error) {
        const newMessage = {
          content: 'Er heeft zich een error voorgedaan bij het genereren van een antwoord. Als dit zich vaker voordoet, contact admin.',
          role: 'chatbot'
        }
        this.messages.push(newMessage);
        this.history = [];
      }
    },
    scrollToBottom() {
      const container = this.$el.querySelector('.messages');
      container.scrollTop = container.scrollHeight;
    },
    logout() {
      this.$cookies.remove('auth_token');
      this.$router.push('/login'); // Redirect to content page on success
    },
    removeHistory() {
      this.messages = [];
      this.history = [];
    }
  },
};
</script>

<style>
* {
  box-sizing: border-box;
}

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden;
  font-family: 'Tahoma', sans-serif;
}

.app-container {
  display: flex;
  height: 100vh;
  overflow: hidden;
  background-color: #f2f2f0;
}

.sidebar-toggle {
  position: fixed;
  top: 20px;
  left: 20px;
  z-index: 5;
  background: none;
  border: none;
  padding: 0;
  font-size: 24px;
  cursor: pointer;
  color: #ffffff;
}

.sidebar {
  background-color: #148cb3;
  color: #fff;
  width: 300px;
  height: 100vh;
  overflow-y: auto;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 4;
  transition: transform 0.3s ease;
}

.sidebar-closed {
  transform: translateX(-300px);
}

.sidebar-content {
  padding: 60px 25px 25px;
  margin-top: 20px;
}

.sidebar-section {
  margin-bottom: 30px;
}

.sidebar-option,
.sidebar-title {
  margin-bottom: 10px;
}

.sidebar-option input[type="radio"] {
  margin-right: 10px;
}

.sidebar-option label {
  cursor: pointer;
}

.main-content {
  flex-grow: 1;
  margin-left: 300px;
  background-color: #f2f2f0;
  color: #000000;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.header {
  background-color: #f2f2f0;
  color: #343541;
  display: flex;
  /* align-items: center; */
  /* margin-left: 5px; */
  padding: 10px;
}

h1 {
  margin-left: 15px;
}

.page-title {
  font-size: 1.2em;
  margin-right: auto;
}

.message-logo {
  height: 50px;
  margin-left: auto;
}

.chat-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 75%;
  margin-left: 12.5%;
  margin-bottom: 0;
}

.message-content {
  white-space: pre-wrap; /* This will keep newlines and wrap text as needed */
}


/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: transparent; /* Make the track background transparent */
  width: 2px; /* Thin track */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(80, 80, 80);;
  border-radius: 2px;
}

.messages {
  height: calc(100vh - 300px);
  padding-bottom: 10px; /* Space for input area */
  position: relative;
  overflow: auto;
  scroll-behavior: smooth;
  scrollbar-color: rgb(80, 80, 80);
  scrollbar-width: 2px;
  font-size: 15px;
  font-family: Arial, Helvetica, sans-serif;
  margin-left: 10px;
  margin-bottom: 30px;
}

.input-area {
  display: flex;
  background-color: #494b55;
  padding: 15px;
  border: 2px solid #063970;
  border-radius: 20px;
  width: 100%;
}

.input-area textarea {
  flex-grow: 1;
  background-color: #494b55;;
  font-size: 16px;
  color: white;
  border: none;
  padding: 10px;
  resize: none;
  word-break: break-word;
  scroll-behavior: smooth;
  scrollbar-color: rgb(80, 80, 80);
  scrollbar-width: 2px;
  height: 100px;
}

.input-area textarea::placeholder {
  color: white;
}

.send-button {
  /*background-color: #e28743;*/
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  padding: 10px 20px;
  border-radius: 4px;
  height: 50px;
  margin-top: 50px;
}

.send-button:hover {
  background-color: #0955a7;
}

.remove-button,
.logout-button {
  background-color: #494b55;
  color: white;
  border: none;
  cursor: pointer;
  padding: 10px 20px;
  border-radius: 4px;
  width: 100%;
  text-align: center;
}

.remove-button:hover, .logout-button:hover {
  background-color: #053261;
}

.input-area textarea:active, .input-area textarea:focus, .input-area textarea:hover {
  border: none;
  border-radius: 0px;
  outline: none;
}

@media (max-width: 768px) {
  .sidebar,
  .main-content {
    width: 100%;
    left: 0;
  }

  .sidebar-closed {
    transform: translateX(-100%);
  }

  .main-content {
    margin-left: 0;
  }

  .sidebar-toggle {
    z-index: 6;
  }

  .input-area {
    flex-direction: column;
  }

  .input-area textarea {
    width: 100%;
    margin-bottom: 10px;
  }

  .send-button,
  .remove-button,
  .logout-button {
    width: 100%;
  }
}
</style>