<template>
  <div class="message" :class="{'user-message': isUser, 'chatbot-message': !isUser}">
    <img :src="messageLogo" class="message-logo" />
    <div  class="bubble" v-html="message.content"></div>
    <!-- <div v-if="isUser" class="bubble" v-html="message.content"></div> -->
    <!-- <div v-else class="bubble">
      <span class="typewriter" v-html="typedContent"></span>
      <span class="cursor" v-if="typing"></span>
    </div> -->
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: Object,
      required: true
    },
    isUser: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      typedContent: '',
      typing: true,
      timeoutId: null // Store the timeout ID
    };
  },
  computed: {
    messageLogo() {
      return this.isUser ? require('@/assets/user.png') : require('@/assets/chatbot.png');
    }
  },
  mounted() {
    if (!this.isUser) {
      this.typeMessage();
    }
  },
  beforeUnmount() { // Updated from beforeDestroy to beforeUnmount for Vue 3
    if (this.timeoutId) {
      clearTimeout(this.timeoutId); // Clear the timeout when component is unmounted
    }
  },
  methods: {
    typeMessage() {
      const content = this.message.content;
      let i = 0;
      const typeWriter = () => {
        if (i < content.length) {
          this.typedContent += content.charAt(i);
          i++;
          const speed = Math.floor(Math.random() * (25 - 4 + 1)) + 3;
          this.timeoutId = setTimeout(typeWriter, speed); // Update the timeout ID
        } else {
          this.typing = false;
        }
      };
      typeWriter();
    },
  }
};
</script>


<style scoped>
.message {
  display: flex;
  align-items: top;
  margin-bottom: 30px;
  max-width: 95%;
  white-space: pre-wrap;
  margin-left: 5px;
}

.user-message .bubble,
.chatbot-message .bubble {
  padding: 10px 20px;
  border-radius: 20px;
  margin-left: 10px;
  word-break: break-word; /* Allow text to wrap */
}

.user-message {
  justify-content: flex-start;
}

.user-message .bubble {
  background-color: #148cb3;
  color: white;
}

.chatbot-message {
  justify-content: flex-start;
}

.chatbot-message .bubble {
  background-color: #148cb3;
  color: white;
}

.message-logo {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  margin-left: 0px;
}

.typewriter {
  display: inline;
  white-space: pre-wrap; /* Allow wrapping and preserve whitespace */
  word-wrap: break-word; /* Break the word */
  overflow: hidden;
}

.cursor {
  display: inline-block;
  vertical-align: bottom;
  width: 2px;
  height: 1em;
  background-color: white;
  animation: blink 1s step-end infinite;
}

@keyframes blink {
  from, to { background-color: transparent }
  50% { background-color: white }
}
</style>