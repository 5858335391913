import { createRouter, createWebHistory } from 'vue-router';
import LoginPage from './pages/LoginPage.vue';
import ContentPage from './pages/ContentPage.vue';
import VueCookies from 'vue-cookies';

const routes = [
  { path: '/login', component: LoginPage, meta: { requiresAuth: false } },
  { path: '/login-callback', component: LoginPage, meta: { requiresAuth: false } },
  { path: '/chat', component: ContentPage, meta: { requiresAuth: true } },
  { path: '/', redirect: '/login' }, // Redirect to /login by default
  { path: '', redirect: '/login' }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

const isAuthenticated = () => {
  return !!VueCookies.get('auth_token');
};

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!isAuthenticated()) {
      next({ path: '/login' });
    } else {
      next();
    }
  } else {
    if (isAuthenticated() && to.path === '/login') {
      next({ path: '/chat' });
    } else {
      next();
    }
  }
});

export default router;
