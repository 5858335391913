import { createApp } from 'vue';
import App from './App.vue';
import router from './router'; // Import the router
import axios from 'axios';
import * as AxiosLogger from 'axios-logger';
import VueCookies from 'vue-cookies'

// If you're using additional plugins, import them here
// import vuetify from './plugins/vuetify';
// ...

const baseURL = process.env.VUE_APP_BASE_URL;
// const BEARER_TOKEN = process.env.VUE_APP_BEARER_TOKEN;
const instance = axios.create({withCredentials: true, baseURL: baseURL});
instance.interceptors.request.use(AxiosLogger.requestLogger);
const app = createApp(App);
app.use(VueCookies);

app.config.globalProperties.$axios = instance; // Making axios globally accessible
app.config.globalProperties.$cookies.config('1h');

app.use(router);
app.mount('#app');
